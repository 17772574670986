body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.btn-ui {
  margin: 5px;
  width: 124px;
  height: 54px;
  padding: 0px;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
  font-weight: 700;
  font-size: 14px;
  color: white;
  background-color: #222;
  transition: background-color 500ms;
  /* transition: filter 150ms; */
}
.btn-ui.color {
  width: 54px; 
  border-radius: 27px;
}
.btn-ui.square {
  border-radius: 16px;
}
.btn-ui:hover {
  filter: invert(20%);
}

.btn-ui.loading {
  cursor: initial !important;
  filter: none !important;
  background-color: #777 !important;
}

h4 {
  margin: 0;
  margin-left: 10px;
  letter-spacing: 1px;
}
